import React from 'react';
import { useParams } from 'next/navigation';

// Components
import MetaHead from '../../../components/MetaHead/MetaHead';
import ChannelHeroBanner from '../../../components/HeroBanner/ChannelHeroBanner';
import TopicLinks from '../../../components/TopicLinks/TopicLinks';
import FetchFeaturedChannelTalk from '../../../libs/FetchFeaturedChannelTalk/FetchFeaturedChannelTalk';
import FetchChannelTalksByStatuses from '../../../libs/FetchChannelTalksByStatuses/FetchChannelTalksByStatuses';
import ChannelHeroBannerSkeleton from '../../../components/HeroBanner/ChannelHeroBannerSkeleton';

// This is a protem hack to fetch the upcoming talks in one block until the API can ne re-written
import FetchChannelTalksByStatusUpcoming from '../../../libs/FetchChannelTalksByStatusUpcoming/FetchChannelTalksByStatusUpcoming';

// Libraries
import FetchSeriesRelated from '../../../libs/FetchSeriesRelated/FetchSeriesRelated';
import getBaseUrl from '../../../libs/getBaseUrl';
import nextCookies from 'next-cookies';
import fetchUserProfile from '../../../libs/fetchUserProfile';
import isUserChannelOwner from '../../../libs/FetchIsChannelOwner/FetchIsChannelOwner';
import sanitize from '@bt-react/libs/sanitize';

// Services
import channelProfileData from '../../../services/channelProfile/channelProfile';
import relatedSeries from '../../../services/relatedSeries';

export default function SingleChannel({
  hostUrl,
  staticAssetUrl,
  seriesUrl,
  userProfileResponse,
  userIsChannelOwner,
  profileResponse,
  seriesCount,
}) {
  type Params = { channelId: string };
  const params = useParams<Params>();

  // UTM params for featured single channel talk.
  const featuredChannelTalkUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=featured';

  /**
   * Function to prepare the params for upcoming/recorded channel talks.
   * @param rank channel datetime/-datetime.
   * @param status channel upcoming/recorded.
   * @returns upcoming/recorded channel params.
   */
  function paramsToFetchDataChannelTalks(status: string) {
    return {
      page: 1,
      size: 8,
      webcastStatus: status,
      channelId: params?.channelId,
    };
  }
  // This is tem until new endpoint built when we should revert to the above params
  function paramsToFetchDataChannelTalksUpcoming(status: string) {
    return {
      page: 1,
      size: 64,
      webcastStatus: status,
      channelId: params?.channelId,
    };
  }
  /**
   * Function to prepare the params for featured talk in channel.
   * @returns featured channel params.
   */

  const paramsToFetchDataFeaturedTalk = {
    page: '1',
    filter: 'closestToNow',
    size: '1',
    channelId: params?.channelId,
  };
  const featuredChannelTalkComponentInfo = {
    priorityStatus: true,
    channelTalkUTM: featuredChannelTalkUTM,
    type: 'channel',
    channelId: params?.channelId,
  };
  const upcomingChannelTalksComponentInfo = {
    gridSize: 8, // this allows us to modify the block size when we fetch all talks to begin with - can be removed after we revert to older pattern
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 3,
    sectionHeading: true,
    sectionHeadingTitle: 'Upcoming talks',
    priorityStatus: true,
    footLinkTitle: '',
    gridItemName: 'channel-talk-item',
    dataBddTitle: 'talks-title-channel',
    dataBddDesc: 'talks-card-description-channel',
    talksUtm:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=upcoming ',
  };

  const recordedChannelTalksComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 3,
    sectionHeading: true,
    sectionHeadingTitle: 'On-demand talks',
    priorityStatus: true,
    footLinkTitle: '',
    gridItemName: 'channel-talk-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
    talksUtm:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=recorded ',
  };

  const paramsToFetchRelatedSeries = {
    page: 1,
    pageSize: 3,
    channelId: params?.channelId, // '7335'
  };
  const relatedSeriesComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColLg: 4,
    rowColXl: 4,
    gridItemName: 'related-series-card',
    sectionHeading: true,
    sectionHeadingTitle: 'Series',
    priorityStatus: true,
    seriesUrl,
    seriesUTM:
      '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=channel-page&utm_content=series-grid',
    footLinkTitle: 'Watch series',
    dataBddTitle: 'related-series-card-title',
    dataBddDesc: 'related-series-card-desc',
    sectionHeadingBddTitle: 'related-series-grid-heading',
  };

  // If this is Stage we don't want gogle indexing channels or
  // if this is a channel with it's searchVisibility key set to one of `exclude`
  // it should be removed from google indexing as but allowed to 'follow' links.
  let robotsIndex = 'index, follow';
  if (hostUrl === 'https://www.stage.brighttalk.net') {
    robotsIndex = 'noindex, nofollow';
  }

  if (profileResponse?.searchVisibility === 'excluded') {
    robotsIndex = 'noindex, follow';
  }

  return (
    <>
      <MetaHead
        title={`${profileResponse?.title} - BrightTALK`}
        pageName="Channel"
        hostUrl={hostUrl}
        staticAssetUrl={staticAssetUrl}
        robots={robotsIndex}
      />

      {(() => {
        if (!profileResponse) {
          return <ChannelHeroBannerSkeleton />;
        } else {
          return (
            <ChannelHeroBanner
              pageType="single-channel-banner"
              theme={'dark'}
              hostUrl={hostUrl}
              channelId={params?.channelId}
              profileData={profileResponse}
              isManager={userProfileResponse?.isManager}
              userIsChannelOwner={userIsChannelOwner}
              seriesCount={seriesCount}
              channelVisibility={
                profileResponse?.channelProfile?.searchVisibility
              }
            />
          );
        }
      })()}

      <div className={'single-channel-content'}>
        {FetchFeaturedChannelTalk(
          paramsToFetchDataFeaturedTalk,
          featuredChannelTalkComponentInfo
        )}
        {/* 
         FetchChannelTalksByStatusUpcoming is a temp solution to load a large block of upcoming channel talks
         due to the endpoint unable to provide a sortOrdering closestToNow
         We should revert this to FetchChannelTalksByStatuses when new endpoint built.
         */}
        {FetchChannelTalksByStatusUpcoming(
          hostUrl,
          paramsToFetchDataChannelTalksUpcoming('upcoming,live'),
          upcomingChannelTalksComponentInfo
        )}

        {FetchChannelTalksByStatuses(
          hostUrl,
          paramsToFetchDataChannelTalks('recorded'),
          recordedChannelTalksComponentInfo
        )}

        {FetchSeriesRelated(
          hostUrl,
          paramsToFetchRelatedSeries,
          relatedSeriesComponentInfo
        )}

        {profileResponse && (
          <TopicLinks topicsKeyWords={profileResponse?.keywordsList} />
        )}
      </div>
    </>
  );
}
// This gets called on every request
export async function getServerSideProps(context) {
  const { BTSESSION } = nextCookies(context);
  const channelId = context.params['channelId'];

  const userIsChannelOwner = await isUserChannelOwner(
    channelId,
    encodeURIComponent(BTSESSION)
  );

  const userProfileResponse = await fetchUserProfile(
    encodeURIComponent(BTSESSION)
  );

  // The channel details (profile) used for the hero banner and for keywords passed
  // to the topics component
  const profileResponse = await channelProfileData(channelId);

  // call relatedSeries API to fetch the series count for meta display
  const seriesParams = {
    channelId: channelId,
    pageSize: 3,
    page: 1,
  };

  const seriesResponse = await relatedSeries(seriesParams);
  const seriesCount = seriesResponse ? seriesResponse['total'].toString() : '';

  // If we do not get the response redirect to the search page.
  if (profileResponse?.code === 'InvalidChannelId') {
    return {
      redirect: {
        permanent: false,
        destination: '/channel-not-found',
      },
    };
  }

  // Iterate Over userProfileResponse Object Properties
  // Update the values after sanitize data
  Object.keys(userProfileResponse).forEach(key => {
    if (userProfileResponse[key]) {
      userProfileResponse[key] = sanitize(userProfileResponse[key]);
    }
  });

  // Iterate Over profileResponse Object Properties
  // Update the values after sanitize data
  const sanitizeItemsToIterateThrough = ['title', 'description', 'organisation', 'keywords', 'strapline'];
  sanitizeItemsToIterateThrough.forEach(key => {
    profileResponse[key] = sanitize(profileResponse[key]);
  });

  return {
    props: {
      baseUrl: getBaseUrl(),
      hostUrl: global.process.env.HOST,
      staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL,
      seriesUrl: global.process.env.SERIES_HOST,
      userIsChannelOwner: userIsChannelOwner,
      userProfileResponse: userProfileResponse,
      profileResponse: profileResponse ? profileResponse : '',
      seriesCount: seriesCount ? seriesCount : '',
    },
  };
}
